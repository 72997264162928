<template>
   <main>
      <slide />

      <!-- destaques  start -->
      <destaques :array="clienteData.destaques" :titulo="$t('home.destaques')" :classes="'lancamentos__area pt-50 pb-50 grey-bg'" />

      
      <!-- about start
      <section class="about__area pt-40 pb-40">
         <div class="container">
            <div class="section__head mb-20">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">Sobre nós</h3>
               </div>
            </div>
            <div class="row align-items-center">
               <div class="col-xl-6 px-xxl-5">
                  <div class="w-img pb-5 pb-xxl-0">
                     <img class="rounded" src="@/assets/cliente/img/banner/empresa-01.jpg" />
                  </div>
               </div>
               <div class="col-xl-6 px-xxl-5 text-justify">
                  <p>A Monkey é o setor da OneWay, empresa de programação, responsável pelo desenvolvimento de scripts. 
                     A OneWay atua no desenvolvimento de softwares para diversas áreas, incluindo a criação de cursos. 
                     Durante a pré-pandemia, a Monkey começou a desenvolver scripts de FiveM, e com o tempo isso virou o 
                     negócio carro chefe da nossa empresa. Hoje, com nosso escritório próprio separado para a Monkey, contamos 
                     com pelo menos 11 colaboradores em seus departamentos sobre a responsabilidade do instrutor, programador e 
                     empresário Édipo Oliveira, vulgo Java. Somos uma empresa de desenvolvimento de sistemas. Trabalhamos com 
                     Fivem a mais de 3 anos, tendo criado diversos Scripts e gerenciando cidades. Atualmente temos um escritório 
                     situado em Fazenda Rio Grande com 11 colaboradores. Além do Fivem trabalhamos também com desenvolvimento de 
                     sistemas em outras linguagens.
                  </p>
               </div>
            </div>
         </div>
      </section> -->
      
      <!-- brand area start -->
      <section class="brand__area pt-40 pb-40 grey-bg">
         <div class="container custom-container">
            <div class="section__head mb-20">
               <div class="w-100 section__title">
                  <h3 class="text-center mb-1">{{ $t('home.tecnologias') }}</h3>
               </div>
            </div>
            <div class="row align-items-center">
               <div class="col-xl-12">
                  <section>
                     <div class="container">
                        <div class="owl-carousel one-show t-nav brand__slider">
                           <div class="brand__item pt-40 pb-40" v-for="(empresa, index) in listaEmpresas" :key="index">
                              <div class="row m-0">
                                 <div class="col-12">
                                    <img :src="empresa.logo == null ? '' : empresa.logo" @error="imageError" class="brand__image" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import slide from '@/components/Slide.vue'
import destaques from '@/components/CarouselDestaques.vue'

export default {
	name: 'Home',
   data: function () {
      return {
         listaEmpresas: [
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/html.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/css.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/js.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/vue-js.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/bootstrap.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/fiveM.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/lua.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/creative.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/vrpex3.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/tomcat.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/wildfly.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/discord.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/java.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/mysql.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/node-js.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/android.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/gtaV.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/jquery.png'},
            {'logo': 'https://worldfivem.com/PlataformaMonkeyUrlImages/gitbook.png'},
            
         ]
      }
   },
   computed: {
		... mapState({
         clienteData: state => state.clienteData,
			isRouteAdmin: state => state.isRouteAdmin
		})
	},
   components: {
      slide, destaques
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      configCarousel : function () {
         Vue.nextTick(function() {
            $('.brand__slider').owlCarousel({
               center: true,
               margin: 8,
               items: 6,
               navText:['<button><i class="fal fa-angle-left"></i></button>','<button><i class="fal fa-angle-right"></i></button>'],
               rewind: true,
               nav: true,
               autoplay: true,
               mouseDrag: false,
               touchDrag: false,
               autoplayTimeout: 5000,
               startPosition: Math.ceil(this.listaEmpresas.length / 2) - 1,
               responsive:{
                  0:{
                     items:1
                  },
                  576:{
                     items:2
                  },
                  767:{
                     items:2
                  },
                  992:{
                     items:4
                  },
                  1200:{
                     items:6
                  },
                  1600:{
                     items:7
                  }
               }
            });
         }.bind(this));
      }
   },
   mounted() {
      if (!this.isRouteAdmin && localStorage.theme != null) {
         $("html").removeClass(localStorage.theme)
      }

      this.configCarousel()
   }
}

</script>